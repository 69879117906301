import React from 'react';
import { BrandedCallout } from '@latitude/callout';
import { Link } from '@latitude/link';
import { Tel } from '@latitude/tel';
import { COLOR } from '@latitude/core/utils/constants';

const CalloutHelp = props => (
  <BrandedCallout
    line1={
      <>
        <Link
          variant={COLOR.WHITE}
          href="https://gemfinance.zendesk.com/hc/en-nz"
          trackId="link-chat-to-us-live"
          trackEventData={{
            label: 'Chat to us live',
            category: 'text-link',
            location: "We're here to help"
          }}
        >
          <strong>Chat to us live</strong>
        </Link>{' '}
        <span css="display:block; margin-bottom: 16px;">
          online Mon to Fri 8:00am - 6:30pm.
        </span>
        <strong css="display:block;">
          Call our Gem Visa team on{' '}
          <Tel no="0800 500 505" color={COLOR.WHITE} />
        </strong>
        <span css="display:block;">Mon to Fri 10:30am - 6:30pm.</span>
      </>
    }
    cta={props.cta}
  />
);
export default CalloutHelp;
